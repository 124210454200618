<template>
  <div class="blueToothBGView">
    <div class="page-header">
      <h1>蓝牙状态
        <small
            :class="{blueStatusGood:blueStatus.opened, blueStatusBad:!blueStatus.opened}"
        >{{
            blueStatus.opened ? '扫描中' : '关闭中'
          }}</small>
      </h1>
    </div>
    <ul class="blueToothList">
      <li class="blueToothLi" v-for="(item) in blueDataList" :key="item.address" @click="pushBlueDetail(item)">
        <blue-list-item :blue="item"></blue-list-item>
      </li>
    </ul>
  </div>
</template>

<script>
import BlueListItem from '@/views/blueTooth/blueToochList/blueListItem/blueListItem'

export default {
  name: 'blueToothList',
  components: { BlueListItem },
  data() {
    return {
      updateBlueCount: 0,
      updateBlueTotalCount: 40,
      blueStatus: {
        opened: false
      },
      blueDataList: []
      // blueDataList: [{
      //   rssi: -40,
      //   uuid: '9A39684572608415C0CE686D4A9718869',
      //   address: 'aa:aa:aa:aa:aa:aa8',
      //   major: 5000,
      //   minor: 5000,
      //   transPower: 3000
      // }, {
      //   rssi: -40,
      //   uuid: "9A39684572608415C0CE686D4A9718869",
      //   address: "aa:aa:aa:aa:aa:aa7",
      //   major: 5000,
      //   minor: 5000,
      //   transPower: 3000,
      // }, {
      //   rssi: -40,
      //   uuid: "9A39684572608415C0CE686D4A9718869",
      //   address: "aa:aa:aa:aa:aa:aa6",
      //   major: 5000,
      //   minor: 5000,
      //   transPower: 3000,
      // }, {
      //   rssi: -40,
      //   uuid: "9A39684572608415C0CE686D4A9718869",
      //   address: "aa:aa:aa:aa:aa:aa5",
      //   major: 5000,
      //   minor: 5000,
      //   transPower: 3000,
      // }, {
      //   rssi: -40,
      //   uuid: "9A39684572608415C0CE686D4A9718869",
      //   address: "aa:aa:aa:aa:aa:aa4",
      //   major: 5000,
      //   minor: 5000,
      //   transPower: 3000,
      // }, {
      //   rssi: -40,
      //   uuid: "9A39684572608415C0CE686D4A9718869",
      //   address: "aa:aa:aa:aa:aa:aa3",
      //   major: 5000,
      //   minor: 5000,
      //   transPower: 3000,
      // }, {
      //   rssi: -40,
      //   uuid: "9A39684572608415C0CE686D4A9718869",
      //   address: "aa:aa:aa:aa:aa:aa2",
      //   major: 5000,
      //   minor: 5000,
      //   transPower: 3000,
      // }, {
      //   rssi: -40,
      //   uuid: "9A39684572608415C0CE686D4A9718869",
      //   address: "aa:aa:aa:aa:aa:aa1",
      //   major: 5000,
      //   minor: 5000,
      //   transPower: 3000,
      // }],
    }
  },
  mounted() {
    this.flutterAction()
    this.$nextTick(() => {
      this.openBluetooth()
    })
  },
  beforeDestroy() {
    this.closeBluetooth()
    this.blueStatus.opened = false
  },
  methods: {
    flutterAction: function() {
      //蓝牙设备回调
      this.flutter.onBluetoothDevices((list) => {
        this.blueStatus.opened = true
        if (this.updateBlueCount < this.updateBlueTotalCount) {
          this.updateBlueCount += 1
          return
        }
        this.updateBlueCount = 0
        this.blueDataList = list
      })
      //扫码回调
      // this.flutter.onQRCode((result) => {
      //
      // })
    },
    openBluetooth: function() {
      this.flutter.openBluetooth()
    },
    closeBluetooth: function() {
      this.flutter.closeBluetooth()
    },
    pushBlueDetail: function(item) {
      this.$push('blueToothDetail', item);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.blueToothBGView {
  height: 100%;
  display: flex;
  flex-direction: column;

  .blueStatusGood {
    color: $color_primary;
  }

  .blueStatusBad {
    color: silver;
  }

  .blueToothList {
    width: 100%;
    height: calc(100% - 60px);
    padding: 0;
    overflow: scroll;

    .blueToothLi {

    }
  }
}

</style>
