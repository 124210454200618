<template>
  <div class="powerItem">
    <div class="powerBgView">
      <div class="powerShowView">
        <div v-show="status>=4" class="powerShow"></div>
      </div>
      <div class="powerShowView">
        <div v-show="status>=3" class="powerShow"></div>
      </div>
      <div class="powerShowView">
        <div v-show="status>=2" class="powerShow"></div>
      </div>
      <div class="powerShowView">
        <div v-show="status>=1" class="powerShow"></div>
      </div>
    </div>
    <div class="powerTitle">
      {{ battery }}
    </div>
  </div>
</template>

<script>
export default {
  name: "powerItem",
  props: {
    battery: {
      default: () => {
        return 2900;
      }
    }
  },
  computed:{
    status:function () {
      // 4 为3000以上, 3 为2900 ～ 3000, 2 为2900 ～ 2800, 1 为 2800以后
      if (this.battery >= 3000) {
        return 4;
      } else if (this.battery < 3000 && this.battery >= 2900) {
        return 3;
      } else if (this.battery < 2900 && this.battery >= 2800) {
        return 2;
      } else {
        return 1;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.powerItem {
  display: flex;
  flex-direction: column;
  width: 60px;
  justify-content: center;
  align-items: center;

  .powerBgView {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 40%;
    height: 30px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .powerShowView {
      width: 90%;
      height: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .powerShow {
        width: 100%;
        height: 50%;
        background-color: $color_primary;
      }
    }
  }

  .powerTitle {
    width: 100%;
    height: 20px;
    line-height: 20px;
    font-size: 15px;
    color: silver;
    text-align: center;
  }


}

</style>
