<template>
  <div class="blueContentItem">
    <div class="contentBGView">
      <div class="headTitle">UUID</div>
      <div class="content content-extension">{{ beacon.uuid }}</div>
    </div>
    <div class="contentBGView">
      <div class="headTitle">mac</div>
      <div class="content content-extension">{{ beacon.address }}</div>
    </div>
    <div class="contentBGView">
      <div class="headTitle">major</div>
      <div class="content content-extension">{{ beacon.major }}</div>
    </div>
    <div class="contentBGView">
      <div class="headTitle">minor</div>
      <div class="content content-extension">{{ beacon.minor }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "blueContentItem",
  props: {
    beacon: {
      type: Object,
      default: () => {
        return {
          uuid: "",
          address: "",
          major: "",
          minor: ""
        }
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.blueContentItem {
  margin-left: 10px;
  display: flex;
  flex-direction: column;


  .contentBGView {
    display: flex;

    .headTitle {
      width: 60px;
      height: 20px;
      font-size: 15px;
      line-height: 15px;
      text-align: left;
      color: silver;
    }

    .content {
      font-size: 15px;
      line-height: 15px;
      text-align: left;

    }

    .content-extension {
      word-wrap:break-word;
      width: calc(100% - 70px);
    }
  }


}

</style>