<template>
  <div>
    <div class="blueListItem">
      <signal-item :rssi="blue.rssi"></signal-item>
      <div class="line"></div>
      <power-item :battery="blue.battery"></power-item>
      <div class="line"></div>
      <blue-content-item class="content" :beacon="blue"></blue-content-item>
    </div>
    <div style="width: 95%;height: 1px;background-color: lightsteelblue;opacity: 0.5"></div>
  </div>
</template>

<script>
import SignalItem from "./signalItem";
import PowerItem from "@/views/blueTooth/blueToochList/blueListItem/powerItem";
import BlueContentItem from "@/views/blueTooth/blueToochList/blueListItem/blueContentItem";

export default {
  props: {
    blue: {
      type: Object,
      default: () => {
        return {
          rssi: 0,
          uuid: "",
          address: "",
          major: 0,
          minor: 0,
          transPower: 0,
          battery:0,
        }
      }
    }
  },
  name: "blueListItem",
  components: {BlueContentItem, PowerItem, SignalItem}
}
</script>

<style lang="scss" scoped>

.blueListItem {
  display: flex;
  margin-top: 10px;

  .line {
    width: 1px;
    background-color: lightsteelblue;
    margin: 5px;
  }

  .content {
    width: calc(100% - 170px);
  }
}

</style>