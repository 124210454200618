<template>
  <div class="signalItem">
    <div class="signalBGView">
      <div class="signalShow">
        <div v-show="status>=1" class="signal" style="height: 20%"></div>
      </div>
      <div class="signalShow">
        <div v-show="status>=2"  class="signal" style="height: 40%"></div>
      </div>
      <div class="signalShow">
        <div v-show="status>=3"  class="signal" style="height: 60%"></div>
      </div>
      <div class="signalShow">
        <div v-show="status>=4"  class="signal" style="height: 80%"></div>
      </div>
    </div>
    <div class="signalTitle">
      {{ rssi + 'DB' }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rssi: {
      default: () => {
        return 0;
      }
    }
  },
  computed: {
    status: function () {
      if (this.rssi >= 0) {
        return 1;
      }
      // 4 为0 ～ -60, 3 为-60 ～ -70, 2 为-70 ～ -80, 1 为 -80以后
      if (this.rssi >= -60) {
        return 4;
      } else if (this.rssi < -60 && this.rssi >= -70) {
        return 3;
      } else if (this.rssi < -70 && this.rssi >= -80) {
        return 2;
      } else {
        return 1;
      }
    },
  },
  name: "signalItem",
  data() {
    return {}
  },

}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.signalItem {
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .signalBGView {
    display: flex;
    width: 50px;

    .signalShow {
      width: 25%;
      height: 40px;
      display: flex;
      justify-content: center;
      transform: rotateZ(180deg);

      .signal {
        width: 60%;
        background-color: $color_primary;
      }
    }
  }

  .signalTitle {
    line-height: 20px;
    height: 20px;
    width: 100%;
    font-size: 15px;
    color: silver;
    text-align: center;
  }
}

</style>
